import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

import "./GroupedBarChart.css";
import { timeParse } from "d3-time-format";

const GroupedBarChart = ({ data, selectedGrouping }) => {
  const svgRef = useRef();
  const selectedXAxis = 'age'

  const parseDates = timeParse("%d %b %Y"); 
  // Adjust the date format according to your data
  data.forEach((record) => {
    record.startDate = parseDates(record.startDate);
  });
  const juneData = data.filter((record) => {
    const startDate = new Date(record.startDate);
    return startDate.getMonth() === 5; // Months are 0-indexed, so June is 5
  });

  const sorted = juneData.slice().sort((a, b) => {
    return new Date(a.startDate) - new Date(b.startDate);
  });

  const sortedage = data.slice().sort((a, b) => {
    return a.age - b.age;
  });

  useEffect(() => {
    const margin = { top: 30, right: 200, bottom: 60, left: 50 };
    const width = 928 - margin.left - margin.right;
    const height = 500 - margin.top - margin.bottom;

    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Group data by location

    const groupedData = d3.groups(
      selectedXAxis === "startDate" ? sorted : sortedage,
      (d) => d[selectedGrouping],
      (d) => d[selectedXAxis]
    );

    // Calculate the intensity sum for each location and each day
    const intensityData = groupedData
      .map(([selectedGroups, selectedGroupingData]) => {
        const dailyIntensity = selectedGroupingData.map(
          ([selectedX, selectedXAxisData]) => ({
            [selectedXAxis]: selectedX,
            intensitySum: d3.sum(selectedXAxisData, (d) => d.intensity),
            [selectedGrouping]: selectedGroups,
          })
        );
        return dailyIntensity;
      })
      .flat();

    // Extract unique ages from intensityData
    const ageData = Array.from(
      new Set(intensityData.map((d) => d[selectedXAxis]))
    );

    const fx = d3
      .scaleBand()
      .domain(new Set(intensityData.map((d) => d[selectedGrouping])))
      .rangeRound([0, width])
      .paddingInner(0.1);

    const ages = new Set(intensityData.map((d) => d[selectedXAxis]));

    let x;

    if (selectedXAxis === "startDate") {
        x = d3.scaleTime()
        .domain([d3.min(intensityData, (d) => d[selectedXAxis]), d3.max(intensityData, (d) => d[selectedXAxis])])
        .range([0, fx.bandwidth()]);
    } else {
      x = d3
        .scaleBand()
        .domain(ages)
        .rangeRound([0, fx.bandwidth()])
        .padding(0.05);
    }

    // const x = d3
    //   .scaleBand()
    //   .domain(ages)
    //   .rangeRound([0, fx.bandwidth()])
    //   .padding(0.05);

    //const color = d3.scaleOrdinal().domain(ages).range(d3.schemeCategory10);
    const color = d3
      .scaleOrdinal()
      .domain(ages)
      .range(d3.schemeSpectral[10])
      .unknown("#ccc");

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(intensityData, (d) => d.intensitySum)])
      .nice()
      .range([height, 0]);

      const legendContainer = d3.select(".legend-items");

const ageLegend = legendContainer
  .selectAll(".legend-item")
  .data(ageData) // Convert the Set to an array
  .enter()
  .append("div")
  .attr("class", "legend-item");

ageLegend
  .append("div")
  .attr("class", "legend-color")
  .style("background-color", (d) => color(d))
  .style("width", "20px")
  .style("height", "20px");

ageLegend
  .append("div")
  .attr("class", "legend-label")
  .text((d) => `${d}`);

    // const ageLegend = d3
    //   .select(".legend-items")
    //   .selectAll(".legend-item")
    //   .data(ageData) // Convert the Set to an array
    //   .enter()
    //   .append("div")
    //   .attr("class", "legend-item");

    // ageLegend
    //   .append("div")
    //   .attr("class", "legend-color")
    //   .style("background-color", (d) => color(d))
    //   .style("width", "20px")
    //   .style("height", "20px");

    // ageLegend
    //   .append("div")
    //   .attr("class", "legend-label")
    //   .text((d) => `${d}`);

    // Append a group for each state, and a rect for each age.
    svg
      .append("g")
      .selectAll()
      .data(d3.group(intensityData, (d) => d[selectedGrouping]))
      .join("g")
      .attr(
        "transform",
        ([selectedGrouping]) => `translate(${fx(selectedGrouping)},0)`
      )
      .selectAll()
      .data(([, d]) => d)
      .join("rect")
      .attr("x", (d) => x(d[selectedXAxis]))
      .attr("y", (d) => y(d.intensitySum))
      .attr("width", x.bandwidth())
      .attr("height", (d) => y(0) - y(d.intensitySum))
      .attr("fill", 'steelblue')
      .attr("fill", (d) => color(d[selectedXAxis]));

    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(fx).tickSizeOuter(0));

    svg.append("g").attr("class", "y-axis").call(d3.axisLeft(y));

    svg
      .append("text")
      .attr("class", "y-axis-title")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", -margin.left)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Migraine Intensity");

    svg
      .append("text")
      .attr("class", "x-axis-title")
      .attr("transform", `translate(${width / 2},${height + 45})`)
      .style("text-anchor", "middle")
      .text("Ages and Their Location");

    svg.selectAll(".y-axis text").attr("dx", "-10");
    // Cleanup
    return () => {
      svg.selectAll("*").remove();
    };
  }, [data, selectedGrouping, selectedXAxis, sorted,sortedage]);

  return (
    <div className="chart-container flex">
    <div className="legend mr-10">
      <h5>Ages</h5>
      <div className="legend-items"></div>
    </div>
    {/* Render your chart */}
    <svg ref={svgRef}></svg>
  </div>
  );
};

export default GroupedBarChart;


