import PropTypes from 'prop-types';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import MigraineHistory from "./MigraineHistory";
import TherapyHistory from "./TherapyHistory";
import QuestionnaireHistory from "./QuestionnaireHistory";
import MeasuremetsHistory from './MeasurementsHistory';

const QueryUserForm = ({
  values, showAll, toggleDiaryShowAll, toggleTherapyShowAll, tabValue,
  toggleQuestionnaireShowAll, toggleMeasurementShowAll, setTabValue
}) => {

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return(
    <>
      <Box>
        <Grid container spacing={3} style={{ margin: '5px', marginTop: '-10px' }}>
          <Grid item xs={4}>
            <Typography 
              color={'text.secondary'}
            >
              <strong>Age: {values.age}</strong>
            </Typography>
          </Grid>

          <Grid item xs={4}>
          <Typography 
              color={'text.secondary'}
            >
              <strong>Sex: {values.sex}</strong>
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography 
              color={'text.secondary'}
            >
              <strong>Race: {values.race}</strong>
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ margin: '5px' }}>
          <Grid item xs={4}>
            <Typography 
              color={'text.secondary'}
            >
              <strong>Occupation: {values.occupation}</strong>
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography 
              color={'text.secondary'}
            >
              <strong>Weight: {values.weight}</strong>
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography 
              color={'text.secondary'}
            >
              <strong>Height: {values.height}</strong>
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ margin: '5px' }}>
          <Grid item xs={4}>
            <Typography 
              color={'text.secondary'}
            >
              <strong>Education Level: {values.educationlevel}</strong>
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography 
              color={'text.secondary'}
            >
              <strong>Martial Status: {values.martialstatus}</strong>
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography 
              color={'text.secondary'}
            >
              <strong>Smoking: {values.smoking ? 'Yes' : 'No'}</strong>
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ margin: '20px 10px 0px 10px' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label='Migraine' value='Migraine' />
          <Tab label='Therapy' value='Therapy' />
          <Tab label='Questionnaire' value='Questionnaire' />
          <Tab label='Measurements' value='Measurements' />
        </Tabs>
      </Box>

      {tabValue === 'Migraine' && <MigraineHistory 
                                          diaryList={values.diaryList} 
                                          showAll={showAll}
                                          toggleShowAll={toggleDiaryShowAll}
                                        />}

      {tabValue === 'Therapy' && <TherapyHistory 
                                          therapyList={values.therapyList} 
                                          showAll={showAll}
                                          toggleShowAll={toggleTherapyShowAll}
                                        />}

      {tabValue === 'Questionnaire' && <QuestionnaireHistory 
                                          questionnaireList={values.questionnaireList} 
                                          showAll={showAll}
                                          toggleShowAll={toggleQuestionnaireShowAll}
                                        />}
      
      {tabValue === 'Measurements' && <MeasuremetsHistory 
                                          measurementList={values.measurementList} 
                                          showAll={showAll}
                                          toggleShowAll={toggleMeasurementShowAll}
                                        />}
    </>
  );
};

QueryUserForm.propTypes = {
  values: PropTypes.object.isRequired,
  showAll: PropTypes.object.isRequired,
  toggleDiaryShowAll: PropTypes.func.isRequired,
  toggleTherapyShowAll: PropTypes.func.isRequired,
  toggleQuestionnaireShowAll: PropTypes.func.isRequired,
  toggleMeasurementShowAll: PropTypes.func.isRequired,
  tabValue: PropTypes.string.isRequired,
  setTabValue: PropTypes.func.isRequired,
};

export default QueryUserForm;